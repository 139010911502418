<template>
  <div class="container ">

    <div  class="section content user">
      <h2 class="section-title">User</h2>
      <div class="setting">
        <!-- {{ authData.username }} -->
        <span>Logout</span>
        <button class="btn-standard" @click="onLogoutClick">Logout 👋</button>
      </div>
   
      <div class="section content">
        <h2 class="section-title">Appearance</h2>
        <div class="setting">
          <span>Dark Mode</span>
          <DarkModeToggle v-model="darkMode" />
        </div>
      </div>


    <div class="section content">
      <h2 class="section-title">App</h2>
      <div class="setting">
        <span>Release Version: </span>
        <span> 1.5 </span>
        <!-- appVersion with appRepo as vue3 link -->
      </div>

      <div class="setting">
        <span>Build Timestamp: </span>
        <!-- appVersion with appRepo as vue3 link -->
        <span>{{ buildTimestamp }}</span>
      </div> 

      <div class="setting">
        <span>Commit: </span>
        <!-- appVersion with appRepo as vue3 link -->
        <a :href="appRepo" target="_blank" rel="noopener noreferrer">{{ appVersion }}</a>
      </div> 
      <div class="setting">
        <span>Learn more</span>
        <router-link to="/about">
            Project Info
        </router-link>

      </div>
    </div>

    <FooterSection />
    
  </div>
</div>

</template>

<script>
import FooterSection from '@/components/FooterSection.vue';
import DarkModeToggle from '@/components/DarkModeToggle.vue';

import { mapActions } from 'vuex';

// import CustomButton from '@/components/CustomButton.vue';

export default {
  name: 'SettingsScreen',
  components: {
    FooterSection,
    DarkModeToggle,
    // CustomButton,
  },
  data() {
    return {
      darkMode: false,
      currentLanguage: 'English',
      languages: ['English', 'Spanish', 'French', 'German'],
      appVersion: '59e9175',
      appRepo: 'https://github.com/philffm/smoothride_ddd_philjwornath/commit/59e91756c7c6a98906e440dd990b2d510b3d9e20',
      buildTimestamp: '26/10/2024 2:50',
    };
  },
  computed: {
        loggedIn() {
        return !!localStorage.getItem('pocketbase_auth') || !!this.token;
      },
    },
  methods: {
    ...mapActions(['logout']),

    onLogoutClick() {
      this.logout({ $pb: this.$pb });
      this.$router.push('/');
    },
  },
};
</script>

<style scoped>

.setting {
  display: flex;
    align-items: center;
    justify-content: space-between;
    width: 320px;
    margin: auto;
}
</style>
