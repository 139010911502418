<template>
  <div>
    <div v-if="submitSuccess" class="container" ref="successScreen">
      <SuccessScreen
        :infrastructure-advice="infrastructureAdvice"
        :content-recs="contentRecs"
        @reset="resetForm"
      />
    </div>

    <div v-if="!submitSuccess" class="container submission-form">
      <div class="submit-header">
        <h2 v-underline-primary>Submit a report</h2>
        <h2 v-if="userName" class="">, {{ userName }} 👋</h2>
      </div>
      <p>
        Share a picture your cycling experiences - and seek advice from ChatGPT
        - be it road obstruction or other nuisances
      </p>
      <ImageUpload
        v-model:fileSelected="fileSelected"
        @update:fileSelected="onFileSelected"
        ref="imageUpload"
        @labels-updated="updateLabels"
        @detected-labels-updated="updateDetectedLabels"
        @file-selected="handleFileSelected"
        @exif-updated="handleExifUpdated"
      />
      <img v-if="!fileSelected" class="explainer" src="explainer.svg" alt="" />
      <p class="fineprint" v-if="!fileSelected">
        By uploading, you acknowledge and agree to our
        <a href="/about#terms">terms of service</a> and
        <a href="/about#data-protection">data protection</a>.
      </p>

      <div class="fade-in" v-if="fileSelected">
        <h2>🚥 Severity</h2>
        <p>How severe would you describe the situation?</p>
        <SeverityScale v-model="selectedSeverity" ref="severityScale" />
      </div>
      <div class="fade-in" v-if="selectedSeverity != null">
        <h2>🔖 Add labels</h2>
        <p>
          The following labels were automatically detected from your provided
          photo. Feel free to add more labels to help training the model.
        </p>
        <div class="loading-spinner" v-if="isLoading">
          <i class="fas fa-bicycle fa-spin"></i>
          <span> Detecting image labels...</span>
        </div>

        <LabelsWrapper
          :labels="allLabels"
          @labels-updated="updateLabels"
          ref="labelsWrapper"
        />
      </div>

      <div v-if="selectedSeverity != null" class="comment fade-in">
        <div class="comment-message">
          <p>
            Describe the situation. The more detailed your comment, the better
            the AI can provide useful suggestions.
          </p>
        </div>
        <textarea v-model="comment" placeholder="Add a comment"></textarea>
        <div class="char-counter">
          <span>{{ comment.length }}</span
          >/500
        </div>
      </div>

      <button
        v-if="selectedSeverity != null"
        class="btn-cta"
        id="seekAdvice"
        @click="getAdvice"
        :disabled="isLoading || comment.length < this.commentBar"
      >
        <span v-if="!isLoading">Seek advice</span>
        <span v-else-if="adviceArrayComplete"> get more advice...</span>
        <span v-else
          ><i class="fas fa-bicycle fa-spin"></i> asking for advice...</span
        >
      </button>
      <AdviceSection
        ref="adviceSection"
        v-if="infrastructureAdvice"
        :h2="'Action-advice by OpenAI 🤖'"
        :subtitle="'The provided advice is based on the labels you provided and the comment you added. You can rate the advice or add your own suggestion so city officials can learn from your experience.'"
        :result="infrastructureAdvice"
        :ratings="ratings"
        @update-rating="updateRating"
        @select-sticky-note="selectedStickyNote = $event"
      />
      <div v-if="adviceArrayComplete" class="comment">
        <div class="comment-message">
          <p>
            How did the AI perform? What would you propose for the situation?
          </p>
        </div>
        <textarea
          v-model="adviceComment"
          placeholder="Add a comment"
        ></textarea>
      </div>
      <button
        v-if="adviceArrayComplete"
        id="submitReport"
        class="btn-cta"
        @click="submitReport"
        :disabled="isLoading"
      >
        <span v-if="!isLoading">Submit Report</span>
        <span v-else>
          <i class="fas fa-spinner fa-spin"></i> Putting you on a map...
        </span>
      </button>
    </div>
  </div>
</template>

<!-- InfrastructureGPT is a platform that allows cycling citizens to upload nuisances and assisted by AI propose improvements to the situation. The platform is based on Vue3, YOLOV8, OpenAI GPT-3.5 and a custom backend based on Flask / Python  -->

<script>
import axios from "axios";
import ImageUpload from "@/components/ImageUpload.vue";
import AdviceSection from "@/components/AdviceSection.vue";
import LabelsWrapper from "@/components/LabelsWrapper.vue";
import SeverityScale from "@/components/SeverityScale.vue";
import SuccessScreen from "@/components/SuccessScreen.vue";
import { errorHandler } from "@/errorHandler.js";
// import { ref } from 'vue';

export default {
  name: "SubmissionForm",
  components: {
    ImageUpload,
    AdviceSection,
    SuccessScreen,
    SeverityScale,
    LabelsWrapper,
  },

  data() {
    return {
      file: null,
      exif: {},
      userName: null,
      userId: null,
      submitSuccess: false,
      comment: "",
      adviceComment: "",
      error: null,
      allLabels: null,
      detectedLabels: null,
      isLoading: false,
      infrastructureAdvice: null,
      contentRecs: null,
      adviceArray: null,
      adviceArrayComplete: null,
      selectedSeverity: null,
      fileSelected: false,
      // useMockData: true,
      mockDataDelay: 500,
      commentBar: 30,
      mockResult: [
        {
          category: "budget friendly",
          advice:
            "Consider using cost-effective materials and designing the infrastructure in a way that minimizes maintenance and repair costs.",
          record_id: "zhg17si53v8af4d",
        },
        {
          category: "fast to achieve",
          advice:
            "Implement bike lanes and pedestrian crossings at strategic locations to improve safety and accessibility, and paint crosswalks and bike lanes in bright colors to increase visibility.",
          record_id: "4dr1z3f4k1tharl",
        },
        {
          category: "best practice",
          advice:
            "Look into successful infrastructure projects in similar cities, and learn from their approach and strategies to improve your own.",
          record_id: "r3bnx3nniua2hrl",
        },
        {
          category: "super creative",
          advice:
            "Create a community bike-sharing program that incentivizes people to use bikes as their primary mode of transportation, and install solar-powered charging stations for electric bikes.",
          record_id: "bd4mk6z4pywrlf4",
        },
      ],
      // BEGIN advicePrompts Iteration 3
      // advicePrompts: [
      //   // { category: 'Budget-friendly', prompt: 'How can the given situation be improved without exceeding a limited budget?' },
      //   { category: 'Fast to achieve', prompt: 'What would be quick wins with low financial, material effort to temporarily fix the given situation?' },
      //   { category: 'Best practice', prompt: 'What would be optimal best practice long-term solution for this situation? - are there international examples?' },
      //   { category: 'Super creative', prompt: 'What would be a super creative "moonshot" way to improve the given situation? Can be super crazy like thinking on how to revert the bad experience into a very good, social interactive one?.' },
      //   { category: 'Citizen action', prompt: 'What is the next step for me as a citizen to improve the given situation?' },
      //   { category: 'Municipality action', prompt: 'What are the next steps for a mobility officer from the municipality to improve the given situation?' },
      // ],
      // END
      // BEGIN advicePrompts Iteration 4
      advicePrompts: [
        // { category: 'Budget-friendly', prompt: 'How can the given situation be improved without exceeding a limited budget?' },
        {
          category: "Fast to achieve",
          prompt:
            "What would be quick wins with low financial, material effort to temporarily fix the given situation?",
        },
        {
          category: "Best practice",
          prompt:
            "Propose the optimal best practice long-term solution for this situation. If applicable, mention specific international examples to get inspired from (not only on country level if possible).",
        },
        {
          category: "Super creative",
          prompt:
            'What would be a super creative "moonshot" way to improve the given situation? How to revert the bad experience into a very good, social interactive one?.',
        },
        {
          category: "Super creative 2",
          prompt:
            "In a parallel universe where reality is shaped by imagination, how would this situation be 100x improved? Stretch the boundaries of possibility, combining elements from different realms or imagining novel solutions",
        },
        {
          category: "Citizen action",
          prompt:
            "As a proactive citizen, what actions can you take that go beyond the ordinary to improve or raise awareness about this situation? Think beyond conventional methods of involvement or protest. If applicable use the location to propose local groups",
        },
        {
          category: "Municipality action",
          prompt:
            "As a chief city planner starting tomorrow, what would be your first steps to tackle this situation? Which other key stakeholders need to be engaged, and how can they be most effectively involved?",
        },
      ],
      // END
      adviceRequests: null,

      // advicePrompts: [
      //   { category: 'Budget-friendly', prompt: 'How can the infrastructure be improved without exceeding a limited budget?' },
      //   { category: 'Fast to achieve', prompt: 'What infrastructure changes can be implemented quickly and efficiently?' },
      // ],
    };
  },
  mounted() {
    this.addMutationObserver();
    if (this.$pb.authStore?.model.username || "") {
      this.userName = this.$pb.authStore?.model.username;
      this.userId = this.$pb.authStore?.model.id;
    }
  },
  beforeRouteLeave(to, from, next) {
    this.removeMutationObserver();
    next();
  },

  computed: {
    fileUrl() {
      if (this.file) {
        return URL.createObjectURL(this.file);
      }
      return null;
    },
  },
  methods: {
    onFileSelected(value) {
      this.fileSelected = value;
      this.isLoading = true;
      // handle file selection event...
    },

    handleExifUpdated(exif) {
      this.exif = exif;
    },
    handleFileSelected(file) {
      this.file = file;
    },

    updateLabels(labels) {
      this.allLabels = labels;
    },
    updateFile(file_name) {
      // this.labeled_file_name = labeled_file_name;
      this.file_name = file_name;
    },
    updateDetectedLabels(labels) {
      this.detectedLabels = labels;
      this.isLoading = false;
    },
    selectStickyNote(index) {
      this.selectedStickyNote = index;
    },

    addMutationObserver() {
      // Watch for changes to the DOM and scroll to the bottom of the page whenever a new element is added
      this.observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          mutation.addedNodes.forEach((node) => {
            if (node.nodeType === Node.ELEMENT_NODE) {
              window.scrollTo(0, document.body.scrollHeight);
            }
          });
        });
      });

      this.observer.observe(document.body, { childList: true, subtree: true });
    },

    removeMutationObserver() {
      if (this.observer) {
        this.observer.disconnect();
      }
    },

    async getAdvice() {
      this.isLoading = true;
      console.log(this.infrastructureAdvice);
      this.adviceRequests++;

      if (this.useMockData) {
        // Use mock data instead of calling the API
        this.infrastructureAdvice = this.mockResult;
        this.infrastructureAdvice = [];

        for (const advice of this.mockResult) {
          const promise = new Promise((resolve) => {
            setTimeout(() => {
              const adviceObject = {
                category: advice.category,
                advice: advice.advice,
                record_id: advice.record_id,
              };
              resolve(adviceObject);
            }, this.mockDataDelay);
          });

          this.infrastructureAdvice.push(await promise);
        }
      } else {
        this.infrastructureAdvice = [];
        for (const advice of this.advicePrompts) {
          try {
            // const prompt = `${advice.prompt}\n\nSeverity: ${this.selectedSeverity}\n\nUser comment: ${this.comment}\n\nLocation: ${this.exif.address}\n\nObjects in the scene: ${this.allLabels
            const prompt = `${advice.prompt}\n\nSeverity: ${
              this.selectedSeverity
            }\n\nUser comment: ${this.comment}\n\nLocation: ${
              this.exif.country
            }, ${this.exif.city}\n\nObjects in the scene: ${this.allLabels
              .map((l) => l.label)
              .join(", ")}`;
            // add the prompt to the array of used prompts
            const response = await axios.post(
              `${process.env.VUE_APP_SERVER_URL}/get-advice`,
              {
                prompt,
                category: advice.category,
                country: this.exif.country,
              },
            );
            // this.promtsUsed.push(prompt);
            const adviceObject = {
              record_id: response.data.record_id,
              category: response.data.category,
              advice: response.data.advice,
            };
            console.log(adviceObject);
            this.infrastructureAdvice.push(adviceObject);
          } catch (error) {
            console.error(error);
            errorHandler(error);
          }
        }
      }
      if (this.infrastructureAdvice) {
        console.log(this.infrastructureAdvice);
      }
      this.adviceArrayComplete = true;
      // Handle errors and loading state
      this.isLoading = false;
      this.removeMutationObserver();
      this.observer.disconnect();
    },

    async getContentRecs() {
      try {
        const infrastructureAdvice = this.infrastructureAdvice
          .map((obj) => obj.advice)
          .join(" ");
        const content = `${
          this.comment
        } ${infrastructureAdvice} ${this.allLabels
          .map((l) => l.label)
          .join(", ")}`;
        console.log(content);
        const response = await axios.post(
          `${process.env.VUE_APP_SERVER_URL}/get-content-recs`,
          {
            content: JSON.stringify(content),
          },
        );
        this.contentRecs = response.data;

        return response.data;
      } catch (error) {
        errorHandler(error);
        console.error(error);
        return null;
      }
    },

    async submitReport() {
      try {
        this.isLoading = true;

        if (!this.exif) {
          const imageUpload = this.$refs.imageUpload.$el;
          imageUpload.scrollIntoView({ behavior: "smooth" });
          const button = document.querySelector(".change-location");
          button.classList.add("flash");
          throw new Error("Please adjust the location of the image");
        }
        // Call the getContentRecs method to get the content recommendations
        if (!this.contentRecs) {
          this.contentRecs = await this.getContentRecs();
        }
        const now = new Date();
        const title = `Report ${now.toLocaleString()}`;

        // Create report data object with advice and content arrays
        const reportData = {
          image_file: this.$refs.imageUpload.image_file,
          labeled_image_file: this.$refs.imageUpload.labeled_image_file,
          // file: this.file.name,
          exif: JSON.stringify(this.exif), // Convert exif dictionary to JSON string
          title: title,
          user_og: this.userId,
          report_public: this.useMockData ? "False" : "True",
          timestamp: now.toISOString(),
          city: this.exif?.city ?? null,
          country: this.exif?.country ?? null,
          region: this.exif?.region ?? null,
          address: this.exif?.address ?? null,
          geo: `${this.exif.latitude},${this.exif.longitude}`,
          severity: this.selectedSeverity + 1 ?? null,
          comment: this.comment,
          advice_comment: this.adviceComment,
          detected_labels: this.detectedLabels,
          all_labels: this.allLabels,
          advice_array: this.infrastructureAdvice ?? null,
          content_array: this.contentRecs ?? null,
          prompts_used: this.promptsUsed,
          iteration_used: 5,
          model_used: this.useMockData ? "mock" : "GPT-4o",
        };

        // If location is not available in exif, prompt the user to select a location using an OSM map picker
        if (!reportData.location) {
          console.log(
            "Location not found in exif. Please select a location on the map.",
          );
        }

        // Send report data to server
        const response = await axios.post(
          `${process.env.VUE_APP_SERVER_URL}/save-report-json`,
          reportData,
        );
        // const response = await axios.post(`${process.env.VUE_APP_SERVER_URL}/save-report`, reportData);

        console.log(response.data);
        console.log(response.data.error);

        // this.isLoading = false;
        this.submitSuccess = true;
        this.$emit(
          "submit-success",
          this.infrastructureAdvice,
          this.contentRecs,
        );
      } catch (error) {
        console.error(error);
        errorHandler(error);
      } finally {
        this.isLoading = false;
        // Remove CSS class to show elements again
      }
    },
    resetForm() {
      // Clear form data on successful submission
      // this.result = null;
      // this.file = null;
      // this.allLabels = [];
      // this.comment = '';
      // this.ratings = [];
      // // this.submitSuccess = false;
      // this.file = null;
      // this.comment = '';
      // this.allLabels = [];
      // // this.result = null;
      // this.ratings = [];
      // this.error = null;
      // location.reload();
    },
  },
};
</script>

<style scoped>
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid var(--color-primary);
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.flash {
  animation: attention 1s ease-in-out infinite !important;
}

@keyframes attention {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.submit-header {
  display: flex;
  justify-content: center;
}

.submission-form input[type="file"] {
  margin-bottom: 30px;
}

img.explainer {
  max-width: 80vw;
  margin-left: auto;
  margin-right: auto;
}
.submission-form .btn-cta {
  background-color: var(--color-primary);
  color: #ffffff;
  font-size: 18px;
  padding: 12px 30px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.submission-form .btn-cta:hover {
  background-color: var(--color-primary-dark);
}

.submission-form label {
  font-weight: bold;
}

.submission-form .form-group {
  margin-bottom: 20px;
}

.submission-form .form-group:last-child {
  margin-bottom: 0;
}

.submission-form .form-group input[type="text"],
.submission-form .form-group .label {
  margin-bottom: 10px;
}

.submission-form .form-group .form-group-header {
  font-weight: bold;
  margin-bottom: 10px;
}

.submission-form .form-group .form-group-header:last-child {
  margin-bottom: 0;
}

.submission-form .form-group .form-group-body {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.submission-form .form-group .form-group-body .form-group-item {
  display: flex;
  align-items: center;
  background-color: var(--color-secondary-light);
  color: var(--color-secondary-dark);
  padding: 8px;
  border-radius: 20px;
}

.submission-form
  .form-group
  .form-group-body
  .form-group-item
  input[type="checkbox"] {
  margin-right: 10px;
}

.submission-form .form-group .form-group-body .form-group-item:last-child {
  margin-bottom: 0;
}

button#seekAdvice,
button#submitAdvice,
button#submitReport {
  margin-bottom: 50px;
}

.char-counter {
  position: relative;
  text-align: end;
  font-size: 14px;
  color: #aaa;
  margin-top: 5px;
}

.btn-cta 

/* .container.submission-form {
    padding-bottom: 80px;
} */

.dark-mode .file-picker-label {
  border-color: #ccc;
}

.dark-mode .comment textarea,
.dark-mode div.labels > form > input[type="text"] {
  background-color: #333;
  color: white;
  border-color: #555;
}
</style>
